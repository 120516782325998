@import "@/styles/vars.scss";

.error {
  position: absolute;
  bottom: -1.2vw;
  font-size: 1vw;
  color: rgb(211, 87, 87);

  @media (max-width: 767px) {
    bottom: -1.8vw;
    font-size: 1.5vw;
  }

  @media (max-width: 576px) {
    bottom: -3.6vw;
    font-size: 3vw;
  }
}

.block {
  position: relative;
  z-index: 5;
  width: 34.375rem;
  padding: 2.5rem 3.5rem;
  align-items: flex-start;
  border-radius: 1.5rem;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  -ms-border-radius: 1.5rem;
  -o-border-radius: 1.5rem;

  box-shadow: 0px 0px 80px 0px rgba(29, 142, 255, 0.15);

  background: #fefefe;

  .title {
    position: relative;
    z-index: 15;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .exchanger {
      display: flex;
      flex-direction: column;
      align-items: center;

      .line {
        position: relative;

        input {
          padding-right: 16.25rem;
        }

        .select {
          position: absolute;
          top: 2.6rem;
          right: 0.4375rem;
        }
      }

      > div {
        width: 100%;
      }

      button {
        margin: 0.5rem 0;
        // margin-top: 0.625rem;
        // margin-bottom: -0.9375rem;
        // width: 2.65vw;
        // height: 2.65vw;
      }
    }

    .telegram {
      position: relative;
    }

    .submit {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .button {
        // width: 22.94vw;

        button {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 767px) {
    width: 57.36vw;
    padding: 3.525vw 4.41vw;

    .title {
      > h4 {
        max-width: 17.76vw;
        font-size: 2.205vw;
      }
    }

    .form {
      margin-top: 2.64vw;
      gap: 2.64vw;

      .exchanger {
        .line {
          input {
            padding-right: 21.75vw;
          }

          .select {
            top: 3.75vw;
            right: 0.4375rem;
          }
        }

        button {
          margin-top: 2.2vw;
          width: 3.975vw;
          height: 3.975vw;
        }
      }

      .submit {
        .button {
          width: 34.41vw;
        }

        .contact {
          button {
            gap: 0.885vw;
            font-size: 1.77vw;

            svg {
              width: 3.315vw;
              height: 3.315vw;
            }
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    width: 89vw;
    padding: 5.05vw 5.82vw;

    .title > h4 {
      max-width: 35.52vw;
      font-size: 4vw;
    }

    .form {
      margin-top: 5.28vw;
      gap: 5.28vw;

      .exchanger {
        .line {
          input {
            padding-right: 43.5vw;
          }

          .select {
            top: 9.4vw;
            right: 1.5vw;
          }
        }

        button {
          margin-top: 3.88vw;
          width: 7.95vw;
          height: 7.95vw;
        }
      }

      .submit {
        .button {
          width: 50.23vw;
        }

        .contact {
          button {
            gap: 1.77vw;
            font-size: 3.54vw;

            svg {
              width: 6.63vw;
              height: 6.63vw;
            }
          }
        }
      }
    }
  }
}
