.root {
  width: 100%;
  padding: 12px 24px;
  border-radius: 12px;
  color: #2396ff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.outline {
  border: 1px solid #2396ff;

  &:hover {
    background-color: rgba(35, 150, 255, 0.1);
  }
}

.filled {
  background: linear-gradient(180deg, #1d8eff -0.92%, #1d8eff 98.25%);
  color: #ffffff;

  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.96px;
  text-transform: uppercase;

  &:hover {
    box-shadow: 0px 0px 50px 8px rgba(0, 0, 0, 0.22) inset;
  }
}

.blur {
  background: #e4f1fe;

  &:hover {
    box-shadow: 0px 0px 35px -20px rgba(0, 0, 0, 0.22) inset;
  }
}

@media screen and (max-width: 1000px) {
  .root {
    padding: 8px 18px;

    font-size: 14px;
  }
}
