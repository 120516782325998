@import "@/styles/vars.scss";

.exchangeInput {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  border: 1px solid #F4F4F4;
  border-radius: 0.75rem;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem;
  -ms-border-radius: 0.75rem;
  -o-border-radius: 0.75rem;

  padding: 0.5rem 1rem;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  label {
    color: #979797;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  span {
    color: $blue;
  }

  input {
    //color: $white;
    height: 3rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0 10px;
    border: none;
    // border-radius: 0.75rem;
    // -webkit-border-radius: 0.75rem;
    // -moz-border-radius: 0.75rem;
    // -ms-border-radius: 0.75rem;
    // -o-border-radius: 0.75rem;
    // border: 0.0625rem solid $blue;
    //background: $blue;

    // &::placeholder {
    //   color: black !important;
    //   opacity: 1 !important;
    // }

    &:focus {
      outline: none;
      box-shadow: none;

      &:hover {
        outline: none;
        box-shadow: none;
      }
    }

    &::placeholder {
      //   color: $white;
      opacity: 0.6;
      font-size: 1rem;
      line-height: normal;
    }
  }

  @media (max-width: 767px) {
    gap: 1.11vw;

    label {
      font-size: 1.545vw;
    }

    span {
      font-size: 1.5vw;
    }

    input {
      font-size: 1.77vw;
      padding: 1.98vw 2.64vw;

      &::placeholder {
        font-size: 1.77vw;
      }
    }
  }

  @media (max-width: 576px) {
    gap: 2.22vw;

    label {
      font-size: 3.09vw;
    }

    span {
      font-size: 2.3vw;
    }

    input {
      font-size: 3.54vw;
      padding: 3.96vw 0;

      &::placeholder {
        font-size: 3.54vw;
      }
    }
  }
}

.feedbackInput {
  display: flex;
  flex-direction: column;

  input {
    // color: $white;
    border-radius: 0.59vw;
    // border: 0.15vw solid $blue;
    background: white;
    font-size: 1.18vw;
    padding: 1.54vw 1.76vw;

    &:focus {
      outline: none;
      box-shadow: none;
      border: none;

      &:hover {
        outline: none;
        box-shadow: none;
        border: none;
        border-radius: 0;
      }
    }

    &::placeholder {
      //   color: $white;
      opacity: 0.5;
      font-size: 1.18vw;
      line-height: normal;
    }
  }

  @media (max-width: 767px) {
    input {
      // border: 0.225vw solid $blue;
      font-size: 1.77vw;
      padding: 2.31vw 2.64vw;

      &::placeholder {
        font-size: 1.77vw;
      }
    }
  }

  @media (max-width: 576px) {
    input {
      // border: 0.45vw solid $blue;
      font-size: 3.54vw;
      padding: 4.62vw 5.28vw;

      &::placeholder {
        font-size: 3.54vw;
      }
    }
  }
}
