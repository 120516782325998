.root {
  padding-bottom: 7.5rem;
}

.logos {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    cursor: pointer;
  }
}
