.root {
  margin: 7.5rem 0 1.25rem 0;

  background-image: url("../../../assets/officesBg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  //   padding-top: -190px;
  padding-bottom: 100px;
}

.content {
  margin-top: 3rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.firstRow {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.secondRow {
  margin-top: 1.5rem;

  display: flex;
  justify-content: center;
  gap: 2rem;
}

@media (max-width: 1000px) {
  .firstRow,
  .secondRow {
    flex-wrap: wrap;
    justify-content: center;
  }
}
