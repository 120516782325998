@import "@/styles/vars.scss";

.root {
  background-color: #ffff;
}

.cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0 34px 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
  }
}

.email {
  color: $blue;
}

.logo {
  align-self: flex-start;
  @media screen and (max-width: 768px) {
    align-self: center;
  }
}

.nav {
  display: flex;
  gap: 72px;
  > ul {
    display: flex;
    flex-direction: column;
    gap: 24px;

    font-size: 16px;
    font-weight: 400;
  }
}

.contacts {
  min-width: 130px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.texts {
  margin-top: 10px;
  p:last-child {
    margin-top: 4px;
  }
}
