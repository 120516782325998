.root {
  margin-top: 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  //   flex-wrap: wrap;
  justify-content: space-between;
  gap: 32px;

  @media (max-width: 1090px) {
    flex-direction: column;
  }
}
