@import "@/styles/vars.scss";

.root {
  display: flex;
  width: 300px;
  padding: 1rem 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.75rem;

  border: 0.0625rem solid $blue;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem;
  -ms-border-radius: 0.75rem;
  -o-border-radius: 0.75rem;

  transition: background-color 0.3s ease-out;
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -ms-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;

  position: relative;

  :after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-image: linear-gradient(180deg, #39b6fe 0.18%, #1d8eff 63.63%);
    opacity: 0;
    transition: all 0.5s ease-in-out;

    border-radius: 0.75rem;
    -webkit-border-radius: 0.75rem;
    -moz-border-radius: 0.75rem;
    -ms-border-radius: 0.75rem;
    -o-border-radius: 0.75rem;

    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;

    z-index: -1000;
  }

  @media screen and (max-width: 540px) {
    width: 100%;
  }
}

.root:hover {
  cursor: pointer;
  // background: linear-gradient(180deg, #39b6fe 0.18%, #1d8eff 63.63%);

  .title {
    color: #ffffff;
  }
  path {
    fill: #ffffff;
  }
}

.root:hover {
  :after {
    opacity: 1;
  }
}

.title {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;

  span {
    text-transform: uppercase;
  }
}
