@import "@/styles/vars.scss";

.block {
  position: relative;
  // background-color: #e3f1fe;
  // border-radius: 0.5rem;
  // -webkit-border-radius: 0.5rem;
  // -moz-border-radius: 0.5rem;
  // -ms-border-radius: 0.5rem;
  // -o-border-radius: 0.5rem;
  cursor: pointer;

  &.coin {
    width: 9.2rem;
    height: 2.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.city {
    // width: 17.5vw;
    width: 100%;

    height: 3.24vw;
  }

  &.cityForm {
    width: 100%;
  }

  .select {
    position: relative;
    z-index: 10;

    &.coin {
      width: 100%;
      padding: 2px 8px;
      background-color: transparent;
      .list {
        // color: black;
        // background-color: $blue;
      }
    }

    &.city {
      width: 100%;
      padding: 1.03vw 1.76vw;
      // background-color: $blue;

      // .list {
      //   background-color: $blue;
      // }
    }

    &.cityForm {
      width: 100%;
      font-size: 1.18vw;
      padding: 1.31vw 1.76vw;
      //   color: $white;
      border-radius: 0.59vw;
      border: 0.15vw solid $blue;

      .list {
        // background: $black-bg;
        font-weight: 600;
      }
    }

    &.focus {
      position: absolute;
      top: 0;
      left: 0;
      //   border: 0.07vw solid $blue;
      // background-color: #e3f1fe;
      border: 1px solid #f4f4f4;
      background: #fefefe;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
    }

    .view {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;

      img {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        object-fit: cover;
      }

      span {
        // color: $white;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      svg {
        // width: 1.76vw;
        // height: 1.76vw;
      }
    }
    .list {
      // left: -0.07vw;

      > ul {
        margin-top: 0.3125rem;
        display: flex;
        flex-direction: column;
        gap: 0.625rem;

        li {
          display: flex;
          align-items: center;
          gap: 0.9rem;
          cursor: pointer;

          img {
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            object-fit: cover;
          }

          span {
            // color: $white;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    &.coin {
      width: 17.25vw;
      height: 5vw;
    }

    &.city {
      width: 26.25vw;
      height: 4.86vw;
    }

    &.cityForm {
      font-size: 1.77vw;
      border-radius: 0.885vw;
    }

    .select {
      border-radius: 0.66vw;

      &.coin {
        padding: 0.885vw 1.32vw;
      }

      &.city {
        padding: 1.545vw 2.64vw;

        .list {
          top: 5.76vw;
        }
      }

      &.cityForm {
        font-size: 1.77vw;
        padding: 1.965vw 2.64vw;
        border-radius: 0.885vw;
        border: 0.225vw solid $blue;
      }

      // &.focus {
      //   border: 0.105vw solid $blue;
      // }

      .view {
        img {
          width: 2.64vw;
          height: 2.64vw;
        }

        span {
          //   color: $white;
          font-size: 1.77vw;
        }

        svg {
          width: 2.64vw;
          height: 2.64vw;
        }
      }

      .list {
        left: -0.105vw;

        > ul {
          margin-top: 1.5vw;
          gap: 1.5vw;

          li {
            gap: 1.5vw;

            img {
              width: 2.64vw;
              height: 2.64vw;
            }

            span {
              font-size: 1.77vw;
            }
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    &.coin {
      // width: 34.5vw;
      // height: 12.57vw;
      width: 8.5rem;
      height: 2.6rem;
    }

    &.city {
      width: 52.5vw;
      height: 9.72vw;
    }

    &.cityForm {
      font-size: 3.54vw;
      border-radius: 1.77vw;
    }

    .select {
      border-radius: 1.32vw;

      &.coin {
        padding: 1.77vw 1.64vw;
      }

      &.city {
        padding: 3.09vw 5.28vw;

        .list {
          top: 11.52vw;
        }
      }

      &.cityForm {
        font-size: 3.54vw;
        padding: 3.93vw 5.28vw;
        border-radius: 1.77vw;
        border: 0.45vw solid $blue;
      }

      // &.focus {
      //   border: 0.21vw solid $blue;
      // }

      .view {
        img {
          width: 5.28vw;
          height: 5.28vw;
        }

        span {
          //   color: $white;
          // font-size: 3.6vw;
          font-size: 0.8125rem;
        }

        svg {
          width: 5.28vw;
          height: 5.28vw;
        }
      }
      .list {
        left: -0.21vw;

        > ul {
          margin-top: 3vw;
          gap: 3vw;

          li {
            gap: 3vw;

            img {
              width: 5.28vw;
              height: 5.28vw;
            }

            span {
              font-size: 0.8125rem;
            }
          }
        }
      }
    }
  }
}

.citySelector {
  cursor: pointer;

  border-radius: 0.75rem;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem;
  -ms-border-radius: 0.75rem;
  -o-border-radius: 0.75rem;
  background: #e4f1fe;

  width: 100%;
  height: 3.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  transition: background-color 0.3s ease-out;
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -ms-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;

  position: relative;

  :after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-image: linear-gradient(180deg, #39b6fe 0.18%, #1d8eff 63.63%);
    opacity: 0;
    transition: all 0.5s ease-in-out;

    border-radius: 0.75rem;
    -webkit-border-radius: 0.75rem;
    -moz-border-radius: 0.75rem;
    -ms-border-radius: 0.75rem;
    -o-border-radius: 0.75rem;

    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;

    z-index: -1000;
  }

  p {
    color: #1d8eff;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  position: relative;

  .list {
    width: 100%;
    // height: 285px;
    // height: 40vh;
    position: absolute;
    top: 60px;
    // border: 1px solid $blue;

    background: #e4f1fe;
    backdrop-filter: blur(25px);

    color: $blue;
    font-size: 1rem;
    font-weight: 700;

    text-transform: uppercase;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 5px 0;
    gap: 5px;
    border-radius: 0.75rem;
    -moz-border-radius: 0.75rem;
    -ms-border-radius: 0.75rem;
    -o-border-radius: 0.75rem;
    -webkit-border-radius: 0.75rem;
    li {
      cursor: pointer;
      height: 54px;
      width: 430px;
      border-radius: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      -webkit-border-radius: 0.75rem;
      -moz-border-radius: 0.75rem;
      -ms-border-radius: 0.75rem;
      -o-border-radius: 0.75rem;

      transition: background-color 0.1s ease;
      -webkit-transition: background-color 0.1s ease;
      -moz-transition: background-color 0.1s ease;
      -ms-transition: background-color 0.1s ease;
      -o-transition: background-color 0.1s ease;

      &:hover {
        color: #ffffff;

        .locationSvg {
          path {
            fill: #ffffff;
            stroke: #ffffff;
          }
        }

        background: #56aaff;
      }

      @media screen and (max-width: 540px) {
        width: 280px;
      }
    }

    @media (max-width: 540px) {
      // height: 32vh;
      font-size: 1rem;
      padding: 10px;
    }
  }
}

.activeCity {
  color: #ffffff;
  background-color: $blue;

  path {
    fill: #ffffff;
    stroke: #ffffff;
  }
}
