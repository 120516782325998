.root {
  padding-top: 50px;

  display: flex;
  justify-content: space-between;
}

.content {
  max-width: 630px;
  align-self: center;

  .text {
    margin-bottom: 40px;
  }

  h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 56px */

    margin-bottom: 16px;
  }
  p {
    color: #727272;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
  }
}

.buttons {
  display: flex;
  gap: 24px;

  max-width: 520px;
}

@media screen and (max-width: 1180px) {
  .root {
    padding-top: 20px;
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
}

@media screen and (max-width: 540px) {
  .content {
    h2 {
      font-size: 1.9rem;
    }
    p {
      font-size: 1rem;
    }
  }
  .buttons {
    width: 100%;
    flex-direction: column;
    gap: 24px;

    max-width: 520px;
  }
}
