.button {
  padding: 5px;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 767px) {
    padding: 0.765vw;
  }

  @media (max-width: 576px) {
    padding: 1.53vw;
  }
}
