.root {
  width:280px;
  height: 156px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 12px;
  border: 1px solid #eaeaea;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h2 {
    color: #121213;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
  }

  p {
    color: #121213;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 241px;
  }
}

@media screen and (max-width: 540px) {
  .root {
    width: 100%;
  }
}
