.root {
  margin-top: 54px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 32px;

  @media screen and (max-width: 1280px) {
    justify-content: center;
  }

  @media screen and (max-width: 540px) {
    gap: 16px;
  }
}
