@import "@/styles/vars.scss";

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6.25rem;
  gap: 1.25rem;

  div {
    display: flex;
    align-items: center;
    gap: 5rem;
  }
  .logo {
    svg {
      // width: 20.59vw;
      // height: 0.96vw;
    }
  }

  .menu {
    ul {
      display: flex;
      gap: 2rem;
    }

    .link a {
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .contacts {
    display: flex;
    align-items: center;
    // gap: 2.35vw;

    // a {
    //   width: 2.21vw;
    //   height: 2.21vw;
    // }

    img {
      cursor: pointer;
    }
  }

  .mobileMenu {
    display: none;
    @media (max-width: 576px) {
      display: block;
      position: relative;
    }
  }

  .menuButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #333;
    outline: none;
  }

  .navIcon {
    width: 8vw;
    height: 6vw;
    position: relative;
    transition-duration: 1s;
    float: right;
    cursor: pointer;

    span {
      top: 3vw;
      height: 0.6vw;
      width: 8vw;
      black-space: normal;
      background-color: black;
      border-radius: 20px;
      position: absolute;
      transition-duration: 0.25s;
      transition-delay: 0.25s;

      &:before {
        left: 0;
        black-space: normal;
        position: absolute;
        top: -2vw;
        height: 0.6vw;
        width: 8vw;
        background-color: black;
        content: "";
        transition-duration: 0.25s;
        transition: transform 0.25s, top 0.25s 0.25s;
      }

      &:after {
        left: 0;
        black-space: normal;
        position: absolute;
        top: 2vw;
        height: 0.6vw;
        width: 8vw;
        background-color: black;
        content: "";
        border-radius: 20px;
        transition-duration: 0.25s;
        transition: transform 0.25s, top 0.25s 0.25s;
      }
    }
  }

  .blur {
    position: fixed;
    z-index: 99;
    background-color: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  .menuContent {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 10vw;
    height: 100vh;
    width: 70vw;
    top: 0;
    right: -150vw;
    background-color: #ffffff;
    padding: 8vw 8vw 10vh 8vw;
    z-index: 100;
    transition: all 0.3s;

    .logo {
      color: $blue;
      font-size: 1.375rem;
      svg {
        width: 54vw;
        height: 2.8vw;
      }
    }

    &.open {
      right: 0vw;
    }

    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      gap: 4vw;
      padding: 0;
      li {
        a {
          font-size: 4.5vw;
          color: $blue;
          opacity: 0.8;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .mobileContacts {
      display: flex;
      gap: 4vw;
      align-items: center;
      justify-content: space-between;

      a {
        width: 8vw;
        height: 8vw;
      }
    }
  }

  @media (max-width: 767px) {
    .menu {
      ul {
        gap: 1.4vw;
      }

      .link a {
        font-size: 1.5vw;
      }
    }

    .contacts {
      a {
        display: none;
      }
    }
  }

  @media (max-width: 576px) {
    height: 16.8vw;

    .logo {
      svg {
        width: 61.8vw;
        height: 2.8vw;
      }
    }

    .menu {
      display: none;
    }

    .contacts {
      display: none;
    }
  }
}
