.root {
  border-radius: 1.5rem;
  border: 0.0625rem solid #eaeaea;
  background: rgba(245, 247, 249, 0.6);
  backdrop-filter: blur(0.125rem);
  display: flex;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  -ms-border-radius: 1.5rem;
  -o-border-radius: 1.5rem;

  @media (max-width: 1090px) {
    height: auto;
  }
}

.icon {
  display: flex;
  flex-direction: column;
  width: 100%;
  img {
    align-self: flex-end;
  }

  @media screen and (max-width: 540px) {
    display: none;
  }
}

.container {
  align-self: flex-end;

  padding: 2.5rem 2.5rem 1.5625rem 2.5rem;

  @media (max-width: 1000px) {
    display: flex;
    gap: 1.5rem;
    .icon {
      order: 2;
      width: auto;
    }
  }

  @media (max-width: 540px) {
    padding: 2.5rem 1.5rem 1.5625rem 2.5rem;
  }
}

.number {
  color: #121213;
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 89.6px */
}

.title {
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  width: 15.875rem;
  margin-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.text {
  color: #727272;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
