@import "@/styles/vars.scss";

.root {
  margin-bottom: 7.5rem;
}

.content {
  margin-top: 3rem;
}

.el {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0.3125rem 1rem 0;
  gap: 2rem;
  border-bottom: 0.0625rem solid #ececed;
  height: fit-content;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;

  @media screen and (max-width: 540px) {
    gap: 3rem;
  }

  &_mod {
    height: fit-content;
  }

  svg {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
  }
}

.title {
  cursor: pointer;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: center;

  p {
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */

    @media screen and (max-width: 540px) {
      font-size: 1rem;
    }
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.text {
  line-height: 140%;
  color: #727272;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /*19.6px*/
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
}

.show {
  opacity: 1;
}
